<script>
import {inject} from "vue";
import {ADMIN_, COMMERCIAL_, COORDINATOR_, SUPERVISOR_} from "@/helpers/constant/roles";
import {getUrlPrefixToShow} from "@/helpers/functions";

export default {
  name: "member-accounts-information",
  props: {
    account: {
      type: Object,
      required: true
    }
  },
  setup(){
    const globals = inject("globals");

    return {globals}
  },
  methods: {
    getUrlPrefixToShow,
  },
  computed: {
    getDetailPrefixUrl(){
      let role = ""
      if (this.globals.roles.IS_ADMIN){
        role = ADMIN_
      } else if(this.globals.roles.IS_SUPERVISOR){
        role = SUPERVISOR_
      } else if(this.globals.roles.IS_COORDINATOR){
        role = COORDINATOR_
      } else {
        role = COMMERCIAL_
      }

      return this.getUrlPrefixToShow(role, "account")
    }
  }
}
</script>

<template>
  <b-card>
    <b-card-header>
      <b-card-title>Compte: {{account.name}}</b-card-title>
    </b-card-header>
    <b-card-body>
      <b-list-group flush class="list-group-fill-success">
        <b-list-group-item class="list-group-item-action"><i class="ri-wallet-line align-middle me-2"></i> <router-link :to="getDetailPrefixUrl + account.uniqId">{{account.identifier}}</router-link></b-list-group-item>
        <b-list-group-item class="list-group-item-action"><i class="bx bx-user align-middle me-2"></i> {{account.name}}</b-list-group-item>
        <b-list-group-item title="Produits souscrits" class="list-group-item-action"><i class="bx bx-shopping-bag align-middle me-2"></i> {{account.subscribedProductCount}}</b-list-group-item>
        <b-list-group-item title="Epargnes" class="list-group-item-action"><i class="bx bx-money align-middle me-2"></i> {{account.savings}} XAF</b-list-group-item>
      </b-list-group>
    </b-card-body>
  </b-card>
</template>

<style scoped>

</style>